








































import Vue from "vue";
import { Getter } from "vuex-class";
import { Component, Ref } from "vue-property-decorator";

// todo make actual slides
@Component
export default class Onboarding extends Vue {
  @Ref("slider") slider!: HTMLDivElement;
  @Getter("profile/userEmailVerified") isEmailVerified!: boolean;

  slide = 0;
  slides = [
    { title: "First slide", text: "First slide text" },
    { title: "Second slide", text: "Second slide text" },
    { title: "Third slide", text: "Third slide text" },
    // etc...
  ];

  back() {
    const last = this.slides.length - 1;
    const next = this.slide - 1 < 0 ? last : this.slide - 1;
    const width = this.slider.clientWidth;
    this.slider.scrollTo({ left: next * width, behavior: "smooth" });
  }
  fwd() {
    const last = this.slides.length - 1;
    const next = this.slide + 1 > last ? 0 : this.slide + 1;
    const width = this.slider.clientWidth;
    this.slider.scrollTo({ left: next * width, behavior: "smooth" });
  }
  jumpTo(index: number) {
    const width = this.slider.clientWidth;
    this.slider.scrollTo({ left: index * width, behavior: "smooth" });
  }
  mounted() {
    let isScrolling: any;
    const scrollWidth = this.slider.scrollWidth;
    const width = this.slider.clientWidth;
    this.slider.onscroll = () => {
      clearTimeout(isScrolling);
      isScrolling = setTimeout(() => {
        clearTimeout(isScrolling);
        const offset = this.slider.scrollLeft;
        const slide = Math.round(offset / (scrollWidth / this.slides.length));
        this.slide = slide;
        this.slider.scrollTo({ left: slide * width, behavior: "smooth" });
      }, 66);
    };
  }
}
